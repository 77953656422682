import {graphql} from 'gatsby'
import React from 'react'

import {Layout, MetaData} from '../../../components'
import {
  ThemePageHeader,
  ThemeFeatureCard,
  FeatureCards,
  Features,
  FeaturesList,
} from '../../../components/ThemePage'
import CTA, {CTAItem} from '../../../components/CTA'

import MembershipIcon from '../../../images/inline-svgs/online-payment.svg'
import GhostIcon from '../../../images/inline-svgs/ghost.svg'
import DocumentationIcon from '../../../images/inline-svgs/documents.svg'
import DownloadIcon from '../../../images/inline-svgs/download.svg'
import ResponsiveIcon from '../../../images/inline-svgs/responsive.svg'
// import FAQIcon from '../../../images/inline-svgs/faq.svg'

const ChroniclesGhostTheme = ({data}) => (
  <Layout>
    <MetaData
      title="Chronicles – A Ghost Blogging Theme"
      description="A ghost blogging theme with elegant serif styling. This theme perfect for writers, photographers, and creative professionals who want to show their work or write a blog."
    />
    <ThemePageHeader
      title="Chronicles"
      eyebrow="Ghost"
      description="A <a href='https://ghost.org' class='link--hover--underline' target='_blank' rel='noopener noreferrer'>Ghost</a> blogging theme with elegant serif styling. This theme perfect for writers, photographers, and creative professionals who want to show their work or write a blog."
      price="$ 30"
      purchaseLink="https://gumroad.com/l/chronicles"
      demoLink="https://chronicles.stylesheets.dev/"
      image={data.header.childImageSharp.gatsbyImageData}
      eyebrowLink="/themes/ghost/"
    />

    <FeatureCards>
      <ThemeFeatureCard
        title="Ghost 4.x"
        description="Supports latest version of Ghost."
      >
        <GhostIcon fill="#444" width="40px" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Memberships Support"
        description="Add Ghost memberships and subscription feature to your site."
      >
        <MembershipIcon fill="#444" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Fully Documented"
        description="Fully documented for setting up and making customizations on your site."
      >
        <DocumentationIcon fill="#444" />
      </ThemeFeatureCard>
    </FeatureCards>

    <Features>
      <FeaturesList>
        <li>Supports latest versions of Ghost 4.0+</li>
        <li>Responsive layout</li>
        <li>Customizable typography and color palette</li>
        <li>Infinite loading layout with load more button</li>
        <li>Ghost premium membership feature support</li>
        <li>Post sharing icons for Twitter and Facebook</li>
        <li>Space for search integration in site navbar</li>
        <li>
          Social media icons included in theme for Facebook, Twitter, Instagram,
          Github, Pinterest, Linkedin, Youtube, Tumblr, Medium, Reddit, 500px,
          Dribbble.
        </li>
        <li>Space for integrating comments from services like Disqus etc.</li>
        <li>Free and regular updates</li>
        <li>Google fonts</li>
      </FeaturesList>
    </Features>

    {/* <StaticImage
      src="../../../images/homepage/collage.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      quality={100}
      style={{
        maxHeight: '330px',
      }}
    /> */}

    <CTA title="Ready to Get Started?">
      <CTAItem
        title="Purchase Theme"
        link="https://gumroad.com/l/chronicles"
        externalLink
      >
        <DownloadIcon fill="#fff" />
      </CTAItem>
      <CTAItem
        title="View Demo"
        link="https://chronicles.stylesheets.dev/"
        externalLink
      >
        <ResponsiveIcon fill="#fff" />
      </CTAItem>
      <CTAItem
        title="Documentation"
        link="https://docs.stylesheets.dev/chronicles"
        externalLink
      >
        <DocumentationIcon fill="#fff" />
      </CTAItem>
      {/* <CTAItem title="FAQs" link="/faq/">
        <FAQIcon fill="#fff" />
      </CTAItem> */}
    </CTA>
  </Layout>
)

export default ChroniclesGhostTheme

export const query = graphql`
  query {
    header: file(relativePath: {eq: "themes/chronicles.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
